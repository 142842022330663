/*=========================
BASIC CSS 
=========================*/

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

:root {
  --color1: #0271e2;
  --color2: #1C72E2;
  --color3: #87CFD4;
  --color4: #7F2FF2;
  --color5: #F9F7EF;
  --color6: #000000;
  --color7: #000000;
}

body,
html {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
  font-weight: 300;
  font-size: 14px;
}

p:last-child {
  margin-bottom: 0 !important;
}

h2 {
  font-weight: 500;
  font-size: 20px;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}


img.loginLogo {
  max-width: 250px;
  margin: auto;
}

div#loginBox {
  text-align: center;
  margin: auto;
  min-width: 400px;
}

form.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}





.box_editor {
  padding-bottom: 90px;
}

.card_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  text-transform: uppercase;
  margin: 0;
}

.card_subtitle {
  color: #B5B5C3;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}


.subtitle {
  color: #9494a1;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
}

.input_group h2 {
  font-weight: 500;
  margin: 0;
}

.box_content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.input_row {
  display: flex;
  gap: 8px;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 1px #bfbfbf;
  background: #fff;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}



div#AmazonResult {
  border: dashed 1px #ddd;
  padding: 20px;
  font-size: 12px;
  word-break: break-all;
}

div#AFAccountContainer {
  width: 450px;
}

.AFBlock {
  border: solid 1px #ccc;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 5px;
  font-size: 12px;
}

.AFBlockRow {
  display: flex;
  justify-content: space-between;
  width: 210px;
}

.AFBlockRow span {
  font-size: 12px;
}

.buttons_top {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

#categories_list, #products_list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}


#categories_list img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 7px;
}

#products_list img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 7px;
}

div#kits_store_container {
  padding-top: 20px;
}

#kits_store .category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product_button {
  border: solid 1px #ddd;
  padding: 20px;
  width: 177px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius:10px;
}

.product_button h2 {
  text-align: center;
  font-weight: 300;
  font-size: 13px;
}

div#kits_store_header {
  display: flex;
  gap: 10px;
  align-items: center;
}

button#backToButton {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding-left: 15px;
}



div#product_detail_miniatures img {
    width: 100px;
}

div#product_detail_images {
    display: flex;
}

div#product_detail_miniatures {
    display: flex;
    flex-direction: column;
}

div#containedLoader {
    position: absolute;
    z-index: 99;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#containedLoader.loaderHide {
    opacity: 0;
    pointer-events: none;
    transition:0.5s;
}



div#product_detail_container {
  display: flex;
}

span.textLabel {
  font-weight: 300;
  font-size: 13px;
}

.product_detail_info_row {
  display: flex;
  flex-direction: column;
}

div#product_detail_info {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

span.textValue {
  font-size: 20px;
}

div#product_detail_main_image img {
  width: 500px;
  height: 500px;
  object-fit: contain;
}


div#kit_products {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.kit_product img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.kit_product h3 {
    font-weight: 300;
    font-size: 11px;
    text-align: center;
}

.noProducts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 0.3;
}

#puesto_header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

#puesto_header_left_right {
  display: flex;
  flex-direction: column;
}

span.puesto_email {
  font-weight: 300;
}

#puesto_header_left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.workstation_product_row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-bottom: solid 1px #ddd;
  justify-content: space-between;
}

.workstation_product_row:last-child {
  border-bottom: none;
}

.workstation_product_name {
  font-weight: 400;
  color: #000;
  font-size: 13px;
}

.workstation_product_price {
  font-size: 12px;
}

#Workstation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: auto;
}

.workstation_product_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.workstation_product_right_left {
  display: flex;
  flex-direction: column;
}

div#puesto_section {
  display: flex;
  gap: 20px;
}

.box.puesto_section_box {
  width: 100%;
}

div#puesto_section_map {
  width: 100%;
  max-width: 600px;
}

div#puesto_section_left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

div#puesto_section_left {
  min-width: 600px;
}

.flexTd {
  display: flex;
  flex-direction: column;
}

.flexTd_bold {
  font-weight: 500;
}

div#user_list {
  width: 100%;
  max-width: 900px;
}

.tdwimg {
  display: flex;
  align-items: center;
  gap: 10px;
}

div#PuestosListIn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

div#puestos_list_header {
  display: flex;
  gap: 10px;
}


.puestos_list_header_item {
  display: flex;
  flex-direction: column;
  padding: 30px !important;
  width: 170px;
}

.puestos_list_header_item h3 {
  margin: 0;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
}

.ModuleHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ModuleHeader h1 {
  margin: 0;
}

.description p {
  font-weight: 300;
  text-align: justify;
  font-size: 14px;
}

.description {
  padding-top: 20px;
  margin-top: 20px;
  border-top: solid 1px #ccc;
}

.description h4 {
  margin-top: 0;
}

.forced_box_editor {
  padding-bottom: 70px !important;
}

.dialog_h2{
  margin: 0;
  line-height: 18px;
}


.dialog_header {
    margin-bottom: 20px;
}

.icon_button_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ModuleContainer {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
}

.listContainer{
  width: 100%;
}

.bottom_fixed_box {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  color: #6b6b6b;
  box-shadow: 0 0 30px #00000012;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99991;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.checkedWorkstation {
  display: flex;
  padding: 0px 10px 0px 0px;
  border: solid 1px #ccc;
  border-radius: 30px;
  gap: 10px;
  align-items: center;
}

.checkedWorkstation_name {
  font-size: 12px;
  line-height: 10px;
}

.checkedWorkstation_email {
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
}

div#checkedWorkstations {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

h3 {
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 5px; */
}

.checkedWorkstation_avatar {
  position: relative;
  left: -1px;
}

#registerSwitchs{
  justify-content: space-between;
}

h2.menu_title {
  color: #9494a1;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 10px;
}

div#menu_companies_container_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search_button {
  display: flex;
}

.LoaderIn {
  display: flex;
  position: absolute;
  background: #ffffffde;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.5s;
}

.MapContainer {
  position: relative;
}

.LoaderIn.hidden {
  opacity: 0;
  pointer-events: none;
}

.workstation_invitation {
  display: flex;
  justify-content: space-between;
  border: solid 1px #d9d9d9;
  padding: 5px 15px;
  align-items: center;
  font-weight: 300;
  border-radius: 5px;

}

div#workstation_invitations {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

span.workstation_invitation_title {
  font-weight: 400;
  color: #000;
  font-size: 13px;
}

span.workstation_invitation_description {
  color: #525252;
  font-size: 13px;
}

.workstation_invitation_left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.workstation_invitation_info {
  display: flex;
  flex-direction: column;
}

.invitation_product_image img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.invitation_product {
  display: flex;
  align-items: center;
  gap: 10px;
}

table tr:last-child td {
  border-bottom: none;
}

h3.title_h3 {
  margin: 0;
}

.box100 {
  width: 100% !important;
}


body,
html {
  margin: 0;
  padding: 0;
}

h2 {
  font-weight: 500;
}

div#structure {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
}

#loader {
  position: absolute;
  z-index: 99991;
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background: #ffffff;
  top: 0;
  left: 0;
}

#loader.loaderHide {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

aside#menu {
  max-width: 240px;
  min-width: 240px;
  height: 100%;
  z-index: 99992;
  background-color: #ffffff;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  display: flex;
  flex-direction: column;
  padding:16px;
}

div#top_bar {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  gap: 10px;
  font-size: 12px;
  z-index: 9999;
}

div#main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#main-content {
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  overflow: auto;
}

div#logo {
  text-align: center;
  padding: 20px 10px 30px;
  font-size: 45px;
  color: #00BCD4;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.menu_button_container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap:40px; 
}

button#menu_button {
  min-width: unset;
  padding: 0;
}


.flex_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}


.card {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 300;
  position: relative;
  box-shadow: 0 0 30px #00000012;
  overflow: hidden;
}

.card_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  text-transform: uppercase;
  margin: 0;
}

.card_subtitle {
  color: #B5B5C3;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}

#project_menu {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 40px;
}

#project_menu a {
  text-decoration: none;
  color: #1A1A1A;
}

h1#title {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}

b {
  font-weight: 500;
}

h1#title b {
  font-size: 20px;
}



.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_row {
  display: flex;
  gap: 8px;
}

.input_row_wrap {
  flex-wrap: wrap;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.estimatePriceInput {
  width: 100%;
}


.box {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 300;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  width: fit-content;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 1px #bfbfbf;
  background: #fff;
}

.box_header {
  margin-bottom: 30px;
}

.box_content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box_header {
  margin-bottom: 30px;
}

.input_group h2 {
  font-weight: 500;
  margin: 0;
}

.buttons_top {
  gap: 8px;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  
}

#ProjectHeader {
  gap: 10px;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}



#estimate_product_box {
  margin: unset !important;
  flex: 1 1 350px;
}

div#project_estimate_products {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}


.add_product_dotted_button {
  border: dashed 2px;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ababab;
  flex: 1 1 350px;
  cursor: pointer;
}

.estimates_th {
  padding: 2px 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  min-width: 68px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 19px;
}

.estimates_prices_table th {
  padding: 5px;
}

.estimates_th_low {
  background: var(--low);
  color: #fff;
}

.estimates_th_mid {
  background: var(--mid);
  color: #fff;
}

.estimates_th_high {
  background: var(--high);
  color: #fff;
}

.estimate_design_fee {

  display: flex;
  align-items: center;
  line-height: 14px;
}

.estimate_design_fee_value {
  font-size: 0.8125rem;
}

.estimate_design_fee:before {
  content: "";
  border-bottom: solid 1px #9a9a9a;
  width: 100%;
  display: inline-block;
  height: 2px;
  margin-right: 5px;
}

.estimate_design_fee:after {
  content: "";
  border-bottom: solid 1px #9a9a9a;
  width: 100%;
  display: inline-block;
  height: 2px;
  margin-left: 5px;
}

.tc_estimate_design_fee {
  padding: 0 !important;
}

.estimates_prices_table td,
.estimates_prices_table th {
  border: none !important;
  padding: 5px !important;

}

.estimate_price_bold {
  font-weight: 500 !important;
  text-align: unset !important;
}

.estimate_price_value {
  text-align: center !important;
  font-weight: 500 !important;
  font-size: 12px;
  text-align: center;
}

.box_editor {
  padding-bottom: 90px;
}




.drawer_in {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 40px;
}


.subtitle {
  font-size: 11px;
}

.sidebar_body_row {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  align-items: center;
}

.epv_low {
  color: var(--low) !important;
}

.epv_mid {
  color: var(--mid) !important;
}

.epv_high {
  color: var(--high) !important;
}

.sidebar_body_row_left {
  display: flex;
  align-items: center;
}

.sidebar_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar_footer_in {
  margin-top: 20px;
}

.textButton {
  color: #000 !important;
}

.cursor_pointer {
  cursor: pointer;
}

.btn_delete_corner {
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: #989898 !important;
}

.drawer_content {
  padding: 0px 22px;
}

.contentWithMiniDrawer {
  display: flex;
  height: 100%;
}

.div_flex {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.MuiTableRow-root:last-child td {
  border-bottom: 0;
}

.graph_row {
  display: flex;
  gap: 10px;
  height: 100%;
}

.graph_column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.graph_row .box {
  width: 100%;
  flex: 1 1 0px;
  padding: 20px;

}

.graph_top_boxes {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph_top_number {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #B5B5C3;
  line-height: 28px;
}

.graph_top_text {
  text-transform: uppercase;
  text-align: center;
  color: #B5B5C3;
  line-height: 19px;
}

div#graph_days_start {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 25px;
  white-space: nowrap;
  line-height: 25px;
  color: #B5B5C3;
}

div#graph_days_deadline {
  text-align: center;
  text-transform: uppercase;
  line-height: 14px;
  color: var(--color1);
}

div#financial_graph .box_content {
  height: 300px;
}

menu#project_menu {
  font-weight: 100;
}

.flex_select {
  display: none !important;
}

.flex_option_1 {
  font-size: 13px;
}

.flex_option_2 {
  color: #9e9e9e;
  font-size: 11px;
}

.MuiAutocomplete-option {
  display: flex !important;
  flex-direction: column;
  align-items: start !important;
}

.MuiAutocomplete-fullWidth {
  width: 100% !important;
}

.autocompleteRelative {
  position: relative;
}

.designFee_input {
  flex: 1 0 21%;
}

div#estimate_products_editor {
  max-width: 700px;
}

.SwitchGroup {
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.flexSimple {
  display: flex;
  gap: 8px;
}

.SwitchGroup h2 {
  position: absolute;
  top: -11px;
  left: 11px;
  margin: 0;
  background: #fff;
  font-size: 12px;
  padding-right: 20px;
  padding-left: 14px;
}

#estimate_configuration h2, #sendEmailDialog h2 {
  margin-bottom: 0;
}

div#QuoteContainter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.categoryRow {
  width: 100%;
  padding: 15px;
}

.dashedButton {
  border: dashed 2px !important;
  color: #ababab !important;
  width: 100%;
}

.QuoteService {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.QuoteServiceItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}


.QuoteServiceCategory {
  color: #fff;
  font-size: 14px;
  writing-mode: tb-rl;
  transform: rotate(180deg);
  background: #ccc;
  padding: 6px 0px;
  transition: 0.5s;
  text-transform: uppercase;
}

.serviceItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemRow {
  display: flex;
  gap: 10px;
}

.itemRow input {
  height: 100%;
}

span.autocomplete_product_code {
  padding: 0 10px;
  background: #cdcdcd;
  font-size: 11px;
  border-radius: 10px;
}


#autocomplete_product-listbox .MuiAutocomplete-option {
  flex-direction: row;
  align-items: center !important;
  gap: 10px;
}

.autocomplete_products {
  min-width: 400px;
}

.autocomplete_sections {
  min-width: 200px;
}

.autocomplete_ic_dots {
  padding: 0 8px !important;
}

.categoryRowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  text-transform: uppercase;
}

.categoryRowHeader h2 {
  margin-top: 0;
}




.th_product {
  min-width: 400px;
  width: 40%;
}

.th_quantity {
  width: 10%;
}

.th_quote_section {
  width: 30%;
}

.th_quote_unit {
  width: 10%;
  text-align: right;
}

.th_quote_cost {
  width: 10%;
  text-align: right;
}

.table_quotes_thead_th {
  font-size: 10px;
}

.table_quotes_tbody {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type_millwork {
  background: var(--color1);
}

.type_stone {
  background: var(--color4);
}

.type_remodeling {
  background: var(--color5);
}

.QuoteServiceHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#autocomplete_product-listbox .MuiAutocomplete-option span {
  font-size: 12px;
}

span.autocomplete_option_small {
  font-size: 12px;
}

.categoryRowTable th,
.measureRowtable th {
  font-size: 10px;
  text-align: left;
  font-weight: 300;
  text-transform: uppercase;
}

.align_right {
  text-align: right !important;
}

.categoryRowTable td,
.measureRowtable td {
  font-size: 12px;
}



#quote_sidebar {
  gap: 5px;
}

#quote_sidebar span {
  font-size: 12px;
}

.total_section .sidebar_body_row {
  border-top: solid 1px #ddd;
  padding: 10px 0;
}

.summary_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 3px;
}

.quote_total_row {
  padding: 5px !important;
  background: #eee;
  font-weight: 500 !important;
}

.total_section {
  margin-top: 50px;
}

.quoteSummaryTitle {
  font-size: 10px;
  color: #fff;
  background: #ccc;
  padding: 2px;
  text-align: center;
  border-radius: 3px;
}

.summary_row {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  padding: 0 5px;
}


.summary_row div {
  font-size: 12px;
}

#SummaryTitleStone {
  background: var(--color4);
}

#SummaryTitleMillwork {
  background: var(--color1);
}

#SummaryTitleRemodeling {
  background: var(--color5);
}

.summary_total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #ddd;
  margin-top: 10px;
  padding-top: 10px;
}

.summary_total_label {
  font-weight: 300;
  font-size: 10px;
}

.summary_total_value {
  font-size: 21px;
}

.DialogTitle {
  padding-bottom: 20px;
}

.DialogHeader {
  margin-bottom: 20px;
}

.DialogHeader span {
  font-weight: 300;
  font-size: 13px;
}

.type_radio_group {
  flex-direction: row !important;
}

.radio_buttons_group {
  margin-top: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 10px 17px;
}

.item_add_button {
  background: #ddd !important;
  color: #5e5e5e !important;
  padding: 2px;
}

.stone_aditional_fields {
  display: flex;
  gap: 4px;
  align-items: center;
}

.stone_fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

span.switchLabel {
  font-size: 12px;
}

span.switchLabel {
  font-size: 12px;
}



.label_workforce {
  display: flex;
  justify-content: flex-end;
}

tr.stoneAuxFields td {
  padding-bottom: 20px;
}

.summary_row_left {
  display: flex;
  align-items: center;
  gap: 5px;
}

div#quoteExplodedView {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.sectionRow {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.sectionProductRowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionProductRowBodyFlex {
  display: flex;
  align-items: center;
}

.measureRowQuantity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120px;
}

table.measureRowtable {
  width: 100%;
}

.measureRowQuantityValue {
  font-size: 30px;
  line-height: 30px;
}

.measureRowQuantityLabel {
  text-transform: uppercase;
  font-size: 12px;
}

.measureRowQuantityPrice {
  font-weight: 500;
  font-size: 18px;
}

.measuresTextTd {
  width: 150px;
}

.MeasureQuantityTd {
  width: 60px;
}

.deleteButtonTd {
  width: 30px;
}

.sectionProductRow {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.drawerImagesContainerDiv {
  position: relative;
}

.deleteImageButton {
  position: absolute !important;
  top: 0;
  right: 0;
}

.sectionProductRowHeader {
  padding: 12px;
  border-bottom: solid 1px #ccc;
}

#filesBrowser {
  display: flex;
  gap: 10px;
  height: 100%;
  width: fit-content;
  margin-bottom: 20px;
}

.fs_row {
  gap: 5px;
  padding: 5px 25px !important;
  color: #959595 !important;
  justify-content: start !important;
  text-transform: inherit !important;
  white-space: nowrap;
}

.box_file_browser {
  min-width: 200px;
  padding: 0;
}

.box_file_browser .box_content {
  gap: 0px;
  height: 100%;
}

.fs_row_selected {
  background: var(--color5);
}

.fs_row_selected span {
  color: #fff;
}

.fs_row.folder svg {
  fill: #438299;
}

.level_folder_name {
  text-align: center;
  font-size: 10px;
  padding: 10px 25px;
  top: 7px;
  width: 100%;
  color: #ccc;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addFolder_button svg {
  fill: var(--color5);
}

.addFile_button svg {
  fill: var(--color1);
}

.file_list {
  display: flex;
  flex-wrap: wrap;
  max-width: 750px;
  margin-bottom: 20px;
}

.box_file_browser .box_children {
  height: 100%;
}

#form_results .box_children, #form_results .box_content{
  height: 100%;
}

.dropzone {
  height: 100%;
}

.dropzone_active {
  box-shadow: 0 0 10px var(--color1);
}

.progressBar {
  display: none !important;
  position: absolute !important;
  top: 0;
  width: 100%;
}

.show_progress_bar {
  display: block !important;
}

div#product_list_header {
  display: flex;
  gap: 10px;
}

div#product_list_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product_list_header_item {
  flex: 1 1;
  border-bottom: 7px solid #E89750 !important;
  flex-direction: column;
  padding: 30px 20px 20px 20px !important;
  justify-content: center;
  align-items: center;
}

div#product_list {
  width: 100%;
}

.product_list_header_item img {
  height: 30px;
}

.product_list_header_item h3 {
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 0;
}

.product_list_header_item span {
  color: var(--color1);
  font-size: 12px;
}

.total_inventory {
  background: var(--color1);
  color: #fff;
  display: flex;
  padding: 20px !important;
  border: none !important;
}

.total_inventory span {
  color: #fff;
}

.total_inventory h3 {
  margin: 0;
}

.category_active {
  border: solid 1px var(--color1) !important;
  border-bottom: 7px solid #E89750 !important;
}

.category_active svg path{
  fill: var(--color1) !important;
}

.msg_save_quote p {
  white-space: break-spaces;
  text-align: center;
  font-size: 14px;
  color: #a0a0a0;
  font-weight: 300;
}


.msg_save_quote svg {
  margin: auto;
  display: inherit;
}

.msg_save_quote svg path {
  fill: #a0a0a0;
}

#explodedViewImages #filesBrowser {
  width: 100% !important;
}

#explodedViewImages .box_file_browser {
  width: 100%;
}

#explodedViewImages .addFolder_button{
  display:none;
}

.file_thumnbnail_image {
  display: flex;
  flex-direction: column;
}

span.thumbnail_name {
  font-size: 10px;
}

.file_thumnbnail {
  display: flex;
  align-items: center;
  gap: 5px;
}

#MainDropMenu a{
  text-decoration: none;
  color: var(--text);
}

div#quote_list {
  width: 100%;
}

img.loginLogo {
  max-width: 150px;
  display: block;
  margin: auto;
}

div#loginBox {
  width: 400px;
  max-width: 90%;
}

div#loginBox {
  text-align: center;
  margin: auto;
}

div#loginBox p {
  font-size: 0.8em;
}

.loginContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
}

#dropFileDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.showOnlyOnHover {
  opacity: 0;
}

.showOnlyOnHover:hover {
  opacity: 1;
  background: #ffffffdb;
}

.drawerImagesContainerDiv {
  display: flex;
  width: 120px;
  height: 114px;
  justify-content: center;
  align-items: center;
  border: dashed 2px #ddd;
  position: relative;
}

.drawerImagesContainerDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone_active {
  border-color: var(--primary);
}

div#project_list_container {
  flex-direction: column;
}

.project_element {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project_element .card_subtitle {
  margin-bottom: 0;
}

.project_element {
  padding: 15px 20px;
}

#project_menu .active {
  color: var(--color1);
}

h2#subtitle {
  margin: 0;
  font-weight: 300;
  color: #828282;
}

.order_product_row {
  display: flex;
  gap: 8px;
}

#order_editor .file_thumnbnail_image {
  max-width: 300px;
}

#order_editor .addFolder_button {
  display: none;
}

.wood_dimension_row {
  display: flex;
  gap: 8px;
  align-items: center;
}


.wood_dimension_row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.boardFeet_value {
  min-width: 100px;
  display: flex;
  justify-content: center;
}

.wood_dimensions_left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectionProductRowHeaderRight {
  width: 100%;
}

.sectionServiceRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectionServicesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type_section {
  text-align: center;
  background: #6d6d6d;
}

.sectionServiceHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.thSpan{
  font-size: 10px;
  text-align: left;
  font-weight: 300;
  text-transform: uppercase;
}

.actionsColumn {
  width: 50px;
  /* prevent elements goes down */
  white-space: nowrap;
}

.quoteStatus {
  font-size: 0.8125rem;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 20px;
  border: dashed 1px;
}

.quoteStatus_1 {
  color: #ce5959;
}

.quoteStatus_2 {
  color: #ce5959;
}

.quoteStatus_3 {
  color: #ce5959;
}

.quoteStatus_4 {
  color: #ce5959;
}

.quoteStatus_5 {
  color: #ce5959;
}

.quoteStatus_6 {
  color: #ce5959;
}

.quoteStatus_7 {
  color: #ce5959;
}

.quoteStatus_8 {
  color: #ce5959;
}

.quoteStatus_9 {
  color: #ce5959;
}

.quoteStatus_10 {
  color: #ce5959;
}

.contentwithMiniDrawer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.titleQuoteType {
  padding: 10px 20px;
  border-radius: 30px;
  color: #808080;
  font-weight: 300;
  background: #ccc;
  cursor: pointer;
}


.ActiveViewButton {
  background: #e2951e;
  color: #fff;
}

.quoteStatusItem {
    display: flex;
    gap: 10px;
    align-items: center;
}

.quoteStatusItem__number {
    background: #6F6F6F;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.quoteStatusItem__date {
    font-size: 11px;
    font-weight: 300;
}

div#quoteStatusContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.quoteStatusItem__status {
    line-height: 14px;
}

h2.titleWIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quoteStatusItem:last-child .quoteStatusItem__number {
  background: #e09220;
}

div#ProjectHeaderButtons {
  display: flex;
  gap: 5px;
}

div#quotePreviewBox {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding-bottom: 70px;
}

.quotePreview__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

.quotePreview_flex_right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

span.quotePreview__header__right__title {
  color: #e3941e;
}

.quotePreview_flex_left {
  display: flex;
  flex-direction: column;
}

.quotePreview__row span {
  font-size: 13px;
}


span.quotePreview__clientInfo__title {
  font-weight: 400;
  color: #000;
}

.QuoteServiceHeaderLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.QuoteServiceHeaderRight {
  width: 300px;
  text-align: right;
}

.quoteTextsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

div#QuotePreviewSummary {
  width: 300px;
  text-align: right;
}

#QuotePreviewSummary .summary_total {
  flex-direction: row;
  justify-content: space-between;
  background: #ddd;
  padding: 5px;
  border-radius: 5px;
}

#QuotePreviewSummary .summary_total .summary_total_label {
  font-size: 12px;
}

#QuotePreviewSummary .summary_total .summary_total_value {
  font-size: 12px;
}

#quotePreviewBox .QuoteServiceTotal {
  font-size: 12px;
}

.box.product_form {
  height: fit-content;
}

.products_area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.drawer_content p {
  text-transform: inherit !important;
}

.drawer_content p { 
  font-weight: 300;
}

.drawer_content span {
  font-weight: 300;
  max-width: 100%;
  white-space: break-spaces;
}

.addButtonCointainer {
  display: flex;
  justify-content: center;
}

.link_1 {
  font-size: 12px;
  color: var(--color1);
  display: flex;
  align-items: center;
  gap: 7px;
  text-decoration: none;
}

.file_thumnbnail_image img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.flexWImage {
  display: flex;
  align-items: center;
  gap: 10px;
}

img.ListImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
  background: #fff;
}

span.flex_title {
  font-size: 13px;
  font-weight: 500;
  display: block;
}

span.flex_subtitle {
  font-size: 13px;
}

.workstationList_image {
  display: flex;
  justify-content: center;
  width: 100%;
}

.boxContainerHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

h2.h2_title {
  font-size: 25px;
  line-height: 20px;
  margin: 0;
}

span.h2_subtitle {
  font-size: 13px;
  font-weight: 300;
}


#userWorkstationsHeader {
  margin-bottom: 10px;
}

.boxWorkstation_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.boxWorkstation {
  padding: 0;
}

.linkUserToWorkstationDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.linkUserToWorkstationDialog span {
  font-weight: 300;
}

.kitContainerFlex {
  display: flex;
  width: 100%;
  gap: 10px;
}

.kitContainer_right {
  background: #ccc;
  padding: 40px;
  background: #0271e2;
  color: #fff;
  border-radius: 5px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.kitContainer_right h2 {
  font-size: 20px;
  margin: 0;
  font-weight: 300;
  text-align: center;
}

.kitContainer_left {
  width: 100%;
}

div#KitsEditorContainer {
  width: 100%;
  text-align: center;
}

.kit_summary_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.kit_summary_item h3 {
  font-size: 13px;
  margin: 0;
  font-weight: 300;
}

.kit_summary_item span {
  font-size: 30px;
  font-weight: 300;
}



.userStatusTimeline {
  display: flex;
  gap: 10px;
  align-items: center;
}


.timelineDivider {
  border-bottom: solid 1px #ccc;
  width: 30px;
  height: 0;
}

.userStatusTimeline__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexRow {
  display: flex;
  align-items: center;
}

.flex_products {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flexModulesColumn {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

div#ServicesList {
  width: 100%;
}

.header_flex {
  display: flex;
  justify-content: space-between;
}

.noAdmins {
  background: #fff;
}

.noAdminsDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.emptyList_content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.emptyList_content h2 {
  color: #000;
  margin: 0;
}

.flexH10{
  display: flex;
  gap: 10px;
  align-items: center;
}

h2.category_title {
  margin: 0;
  font-weight:300;
}

h2.categoryTitle {
  font-weight: 300;
  font-size: 15px;
}


span.kitProductPrice {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.kit_product_info {
  height: 50px;
}



.kit_product {
  width: 100px;
  position: relative;
  text-align: center;
}

.deleteButtonOnImage {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
}

.kit_product:hover .deleteButtonOnImage {
  opacity: 1;
}

.kit_summary {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.kit_summary_item {
  border-bottom: solid 1px #ffffff45;
  padding-bottom: 30px;
}

.kit_summary_item:last-child {
  border: none;
}

.workstation_product_left {
  display: flex;
  gap: 10px;
}


.user_invitation_header {
  display: flex;
  gap: 20px;
  align-items: center;
}

div#budgetValue {
  display: flex;
  flex-direction: column;
  border-left:solid 1px #3597ff;
  box-shadow:-1px 0px #075ab1;
  padding-left:10px;
}


div#budgetValueContainer {
  display: flex;
  background: #0271e2;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  align-items:center;
  gap:10px;
}

span.label1 {
  font-size: 11px;
  font-weight: 300;
}

span.value1 {
  line-height: 18px;
}


.budgetSummary {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #e5e5e5;
  padding-top: 15px;
}

.budgetSummary span {
  font-weight: 300;
}

.indicador_tipo_1 {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 300;
  position: relative;
  box-shadow: 0 0 30px #00000012;
  flex: 1 1 0;
  width: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}






.ind_1_fila_datos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}

.ind_1_icono {
  background: #fff;
  color: var(--color3);
  padding: 10px 10px 20px 10px;
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 49% 100%, 0 80%);
  width: 140px;
  height: 100px;
}

div#icono_productividad_1 {
  font-weight: 500;
  font-size: 2em;
  line-height: 26px;
}

div#icono_productividad_2 {
  font-weight: 500;
  font-size: 1.2em;
  line-height: 19px;
}



div#icono_trafico_1 {
  font-weight: 500;
  font-size: 2em;
  line-height: 26px;
}

div#icono_trafico_2 {
  font-weight: 500;
  font-size: 1.2em;
  line-height: 19px;
  white-space: nowrap;
}

.ind_1_dato {
  font-size: 4em;
}

div#icono_productividad {
  background: #8ab33c;
  color: #fff;
  padding: 22px 10px 20px 10px;
  text-align: center;
}

#contenedor_dato_productividad {
  color: #8ab33c;
  border-top:solid 10px #8ab33c;
}

div#icono_co2_2 {
    font-weight: 500;
    font-size: 3em;
    line-height: 35px;
}

div#contenedor_dato_trafico {
  color: var(--color2);
  border-top:solid 10px var(--color2);
}

div#icono_trafico {
  background: var(--color2);
  color: #fff;
  padding: 22px 10px 20px 10px;
  text-align: center;
}

div#contenedor_dato_co2 {
  color: var(--color3);
  border-top:solid 10px var(--color3);
}

#icono_co2{
  background: var(--color3);
  color: #fff;
  text-align: center;
}

div#contenedor_dato_productividad {
  color: var(--color8);
  border-top:solid 10px var(--color8);
}

div#icono_productividad{
  background: var(--color8);
  color: #fff;
  text-align: center;
}

.graph_row .box_header {
  margin: 0;
}

div.boxContainerActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

button.menu_button {
  padding-left: 0px;
}

div.no-gap {
  gap: 0;
}

div.row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

div.row.no-gap {
  gap: 0;
}

div.row.centered {
  align-items: center;
}

div.spaced {
  justify-content: space-between;
}

.timeLineSeparator {
  min-width: 34px;
  justify-content: start;
  align-items: center;
}

.side-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.dataDisplayer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
}

.dataDisplayer .dataDisplayerHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.dataDisplayer .dataDisplayerHeader .dataDisplayerState {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}

.dataDisplayer .dataDisplayerInformation {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  border-bottom: solid 1px #eee;
  padding: 10px 20px;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 0.3;
}

#newMessageBox .box_header {
  margin-bottom: 10px;
}

#newMessageBox .box_header .card_subtitle, #messageBox .box_header .card_subtitle {
  margin-bottom: 0px;
}

#newMessageBox .box_content .editor-shell {
  margin: 0;
}

#newMessageBox .box_content .editor-shell {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}
#newMessageBox .box_content .editor-shell .editor-scroller {
  height: 110px;
}

#newMessageBox .box_content .editor-shell .toolbar {
  border-bottom: solid 1px lightgray;
}

#messageBox .box_header {
  margin-bottom: 0px;
}

#messageBox .box_content .editor-shell {
  margin: 25px;
}

#messageBox .box_content .editor-shell .ContentEditable__root {
  padding: 0;
  min-height: unset;
}

#messageBox .box_content .editor-shell .editor-scroller {
  min-height: unset;
  height: auto;
  resize: none;
}

#messageBox .box_content .editor-shell .actions {
  display: none;
}

#messageBox .box_content .editor-shell .toolbar {
  display: none;
}

.columns2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.empty_element {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.empty_element_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a0a0a0;
  font-size: 14px;
}

.empty_element_subtitle {
  font-weight: 300;
}

.empty_element_content img {
  opacity: 0.3;
}

span.empty_element_title {
  font-weight: 500;
}

#form_results {
  display: flex;
  width: 100%;
  gap: 10px;
}

.result_row {
  justify-content: space-between;
  display: flex;
  font-size: 13px;
}

#form_results .box {
  flex: 1 1 0px;
}



#first-steps-header h2 {
  margin-bottom: 0px;
}


#progressbar-container .MuiLinearProgress-root {
  flex: 1 1 0px;
}

#progressbar-container #progressbar-label {
  font-size: 12px;
  font-weight: 200;
}

#progressbar-container {
  position: relative;
}

#progressbar-container img {
  position: absolute;
  top: -20px;
  left: 40px;
  z-index: -1;
}

.welcomeHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: solid 10px #ebebeb;
  margin-bottom: 20px;
}

.welcomeH2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  color: #000;
}

.welcomeP {
  text-align: center;
  color: #000;
}