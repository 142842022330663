.listado_modulo {
    background: #fff;
    padding: 40px;
    margin: auto;
    box-shadow: 0px 0px 30px #b5b5c240;
    border-radius: 5px;
    width: fit-content;
    max-width: 100%;
    font-size: 13px;
}

.listado_modulo_extendido {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 30px #00000012;
    color: #000;
    font-weight: 300;
    padding: 40px;
    position: relative;
    width: 1080px;
}

.formSmallMessage {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
}

.narrowElement {
    padding: 0px;
}

.narrow-vertical-element {
    padding-top: 0px;
    padding-bottom: 0px;
}

.condensedElement {
    margin: 0px auto;
}

.compact-list {
    display: flex;
    flex-direction: column;
    row-gap: 0;
    margin: 0 5px;
    padding: 0;
    width: 80px;
    align-items: center;
    justify-content: center;
}

.compact-content {
    width: fit-content;
}

.equalize {
    display: flex;
    justify-content: space-between;
}

.list-item-medium {
    max-width: 50%;
    min-width: 30%;
    width: 40%;
    font-size: 12pt;
}

.list-item-small {
    max-width: 30%;
    width: max-content;
    font-size: 10pt;
}

.modulo-amplio {
    min-width: 50%;
}

#table_body_answers .formContainer .formItem {
    padding: 10px 10px 10px 0px;
}

#table_body_answers .formContainer .formItem .formInput {
    width: 100%;
}

#table_body_answers .formContainer .formItem .formInputCheck {
    width: 100%;
}

#table_body_answers .formContainer .formItem .formInputCheck:first-child {
    width: fit-content;
    padding-right: 5px;
}

#table_body_answers .formContainer .formItem .formInputCheck:last-child {
    width: -webkit-fill-available;
    padding-right: 0;
}